import { getConfig } from "@src/config";
import { getDefaultFilter } from "@src/shared/context/searchfilter/IFilter";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import useSearchResult from "@src/shared/context/searchresult/useSearchResult";
import InfoIcon from "@src/shared/infoIcon/InfoIcon";
import InputFilter from "@src/shared/searchResults/searchfilter/filter/inputfilter/InputFilter";
import FormattedCurrency from "@src/shared/utils/FormattedCurrency";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FilterContainer, FilterControl, FilterLabel, FilterLabelContainer, FilterMessage } from "./Filters.sc";

interface DownpaymentFilterProps {
    startAdornment?: string;
    endAdornment?: string;
    hasMinMaxCheck?: boolean;
    infoIconText?: React.ReactNode;
    disableInfoIcon?: boolean;
}

const DownpaymentFilter: React.FC<DownpaymentFilterProps> = ({
    startAdornment,
    endAdornment,
    hasMinMaxCheck = false,
    infoIconText,
    disableInfoIcon = false,
}) => {
    const intl = useIntl();
    const filter = useSearchFilter();

    const searchResult = useSearchResult();

    return (
        <FilterContainer>
            <FilterLabelContainer>
                <FilterLabel>
                    <FormattedMessage id="leaseme.downPayment.label" defaultMessage="Eigenleistung" />
                </FilterLabel>
                {!disableInfoIcon && (
                    <InfoIcon
                        color="white"
                        infoText={
                            infoIconText || (
                                <FormattedMessage
                                    id="leaseme.downPayment.message"
                                    defaultMessage="{financeType, select, leasing {{downPaymentLeasing}} other {{downPaymentCredit}}}"
                                    values={{
                                        financeType: filter.filter.financeType,
                                        downPaymentLeasing: intl.formatMessage({
                                            id: "leaseme.downPayment.message.leasing",
                                            defaultMessage:
                                                "Die Eigenleistung ist hier ein vorzeitig rückzahlbares Depot. Das heißt, dass die Einmalzahlung auf die vereinbarte Laufzeit aufgeteilt wird. Damit verringert sich das monatliche Leasingentgelt. Die Eigenleistung darf maximal 50% des Fahrzeugpreises netto betragen.",
                                        }),
                                        downPaymentCredit: intl.formatMessage({
                                            id: "leaseme.downPayment.message.credit",
                                            defaultMessage:
                                                "Durch eine Anzahlung reduzieren sich Ihre monatlichen Raten. Möglich ist eine Anzahlung bis zu 40% des Kaufpreises.",
                                        }),
                                    }}
                                />
                            )
                        }
                    />
                )}
            </FilterLabelContainer>
            <FilterControl>
                <InputFilter
                    value={filter.filter.downPayment.toString()}
                    onChange={(value) => {
                        if (filter.setFilter && value) {
                            filter.setFilter("downPayment", parseInt(value));
                        }
                    }}
                    startAdornment={startAdornment}
                    endAdornment={endAdornment}
                    maxValue={9999999}
                    minValue={0}
                    defaultValue={getDefaultFilter(getConfig("scope").domain).downPayment}
                    startAdornmentPadding={8}
                    endAdornmentPadding={8}
                    isCurrency
                    inputMode="numeric"
                />
            </FilterControl>
            {hasMinMaxCheck &&
                searchResult.facets?.downPayment?.min &&
                searchResult.facets.downPayment.min > filter.filter.downPayment &&
                !searchResult.loading && (
                    <FilterMessage>
                        <FormattedMessage
                            id="leaseme.downPayment.messageTooLow"
                            defaultMessage="Keine Ergebnisse für die gesetzten Parameter. Bitte gib eine Eigenleistung von mindestens {minValue} ein."
                            values={{
                                minValue: <FormattedCurrency value={searchResult.facets.downPayment.min} />,
                            }}
                        />
                    </FilterMessage>
                )}
            {hasMinMaxCheck &&
                searchResult.facets?.downPayment?.max &&
                searchResult.facets.downPayment.max < filter.filter.downPayment &&
                !searchResult.loading && (
                    <FilterMessage>
                        <FormattedMessage
                            id="leaseme.downPayment.messageTooHigh"
                            defaultMessage="Keine Ergebnisse für die gesetzten Parameter. Bitte gib eine Eigenleistung von maximal {maxValue} ein."
                            values={{
                                maxValue: <FormattedCurrency value={searchResult.facets.downPayment.max} />,
                            }}
                        />
                    </FilterMessage>
                )}
        </FilterContainer>
    );
};

export default DownpaymentFilter;
